const DashboardLogo = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
    >
      <path
        d='M11.333 6.66658H12.6663C13.9997 6.66658 14.6663 5.99992 14.6663 4.66659V3.33325C14.6663 1.99992 13.9997 1.33325 12.6663 1.33325H11.333C9.99967 1.33325 9.33301 1.99992 9.33301 3.33325V4.66659C9.33301 5.99992 9.99967 6.66658 11.333 6.66658Z'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.33301 14.6666H4.66634C5.99967 14.6666 6.66634 13.9999 6.66634 12.6666V11.3333C6.66634 9.99992 5.99967 9.33325 4.66634 9.33325H3.33301C1.99967 9.33325 1.33301 9.99992 1.33301 11.3333V12.6666C1.33301 13.9999 1.99967 14.6666 3.33301 14.6666Z'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.99967 6.66658C5.47243 6.66658 6.66634 5.47268 6.66634 3.99992C6.66634 2.52716 5.47243 1.33325 3.99967 1.33325C2.52691 1.33325 1.33301 2.52716 1.33301 3.99992C1.33301 5.47268 2.52691 6.66658 3.99967 6.66658Z'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9997 14.6666C13.4724 14.6666 14.6663 13.4727 14.6663 11.9999C14.6663 10.5272 13.4724 9.33325 11.9997 9.33325C10.5269 9.33325 9.33301 10.5272 9.33301 11.9999C9.33301 13.4727 10.5269 14.6666 11.9997 14.6666Z'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DashboardLogo;
