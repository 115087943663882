import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CookiesService from "../../services/CookiesService";
import LocalStorage from "../../services/LocalStorage";
import { resetUserData } from "../store/slice/userData";
import { resetSelectedChildData } from "../store/slice/selectedChild";

const useAuth = () => {
  const [isUserLogin, setIsUserLogin] = useState(false);
  const navigate = useNavigate();
  const userDataDispatch = useDispatch();
  const selectedChildInChildSelectDispatch = useDispatch();

  useEffect(() => {
    if (LocalStorage.get.accessToken()) {
      setIsUserLogin(true);
    } else {
      setIsUserLogin(false);
    }
  }, []);

  const logoutUser = () => {
    LocalStorage.clear();
    userDataDispatch(resetUserData());
    selectedChildInChildSelectDispatch(resetSelectedChildData());
    CookiesService.clear.geoLocation();
    navigate("/authentication", {
      replace: true,
      state: { redirectTo: "/account/dashboard", contestName: "" },
    });
  };

  return { isUserLogin, logoutUser };
};

export default useAuth;
