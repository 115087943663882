import axios from 'axios'
import axiosConfig from './axiosConfig'
import { PaymentFormDetails, PaymentType } from 'models';

const baseURL = process.env.REACT_APP_BASE_URL

export const PaymentService = {
  initiatePayment: (paymentDetails: any) =>
    axios.post(`${baseURL}/payment/initiate`, paymentDetails, axiosConfig()),

  paymentSuccessful: (details: any, paymentId: string) =>
    axios.put(
      `${baseURL}/payment/success/${paymentId}`,
      details,
      axiosConfig()
    ),

  paymentFailed: (details: any, paymentId: string) =>
    axios.put(`${baseURL}/payment/fail/${paymentId}`, details, axiosConfig()),

  getPaymentDetails: (paymentId: string) => axios.get(`${baseURL}/payment/payment_code/${paymentId}`, axiosConfig()).then(res => {
    if (res.data.response_code === 80) {
      const paymentType = res.data.data.payment_code_type;
      const data = res.data.data;

      if (paymentType === PaymentType.SUBSCRIPTION) {
        const paymentDetails: PaymentFormDetails = {
          paymentType: PaymentType.SUBSCRIPTION,
          agentId: data.agent_id || '',
          id: data.subscription_id,
          name: data.name || "Subscription",
          durationInMonths: 12, // TODO: change this data.duration_in_months
          amount: data.price_in_INR,
          originalAmount: data.original_price_in_INR,
          benefits: data.detailed_benefits,
          contestLevel: data.contest_level || '1',
        }
        return paymentDetails;
      } else if (paymentType === PaymentType.COMBO) {
        const paymentDetails: PaymentFormDetails = {
          paymentType: PaymentType.COMBO,
          agentId: data.agent_id || '',
          id: data.combo_code,
          name: data.combo_name || "Combo",
          discountPrice: data.discount,
          maximumContestants: data.combo_item_count,
          contestLevel: data.contest_level || '1',
        }
        return paymentDetails;
      } else {
        throw new Error("Invalid payment type");
      }

    } else {
      throw new Error(res.data.developer_message);
    }
  }).catch(err => {
    throw new Error(err);
  }),
  initiatePublicPayment: (paymentId: string, payload: any) => axios.post(`${baseURL}/payment/payment_code/${paymentId}/initiate_payment`, payload, axiosConfig())
}
