import { useEffect, useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { ChevronDown } from "react-feather";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserData } from "../../utils/store/slice/userData";
import LogoutAlertDialog from "../LogoutAlertDialog";

// import ChildrenProfiles from "../ChildrenProfiles";
// import SearchBox from "../SearchBox";

import "./style.scss";
import useAuth from "../../utils/customHooks/useAuth";
import ChildSelect from "../ChildSelect";

// const childrenDetails = [
//   {
//     name: "Riya",
//     imgSrc: "/assets/user.png",
//   },
//   {
//     name: "Jack",
//     imgSrc: "/assets/user.png",
//   },
// ];

const Header = () => {
  // const [notificationCount, setNotificationCount] = useState(0);
  const [profilePicURL, setProfilePicURL] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { logoutUser } = useAuth();

  const userData = useSelector(selectUserData);

  // const getNotificationCount = () => {
  //   // TODO:- get notification count from backend
  //   // setNotificationCount(1);
  // };

  useEffect(() => {
    // getNotificationCount();
  }, []);

  useEffect(() => {
    if (userData.profilePictureUrl) {
      setProfilePicURL(userData.profilePictureUrl);
    } else {
      setProfilePicURL("/assets/user-solid.png");
    }
  }, [userData]);

  return (
    <div className="d-none d-md-flex p-3 ps-md-5 align-items-center">
      {/* <SearchBox />
      <ChildrenProfiles childrenDetails={childrenDetails} />
      <button className="me-4 primary-transparent-button notification-button">
        <img
          src="/assets/notification.svg"
          alt="notifications"
          width="32px"
          height="32px"
        />
        {notificationCount && (
          <span className="notification-count">
            {notificationCount > 10 ? "9+" : notificationCount}
          </span>
        )}
      </button> */}
      <div className="ms-auto me-3">
        <ChildSelect />
      </div>
      <div className="profile-dropdown">
        <DropdownButton
          className="p-0"
          variant=""
          title={
            <span className="d-flex align-items-center">
              <div
                className="profile-pic"
                style={{ backgroundImage: `url(${profilePicURL})` }}
              ></div>
              <div className="dark-blue-text text-start ms-3 me-2 profile-data">
                <div className="profile-name">{userData.name}</div>
                {/* <div className='profile-designation'>Designation</div> */}
              </div>
              <ChevronDown />
            </span>
          }
        >
          <Dropdown.Item
            onClick={() => {
              navigate("settings");
            }}
          >
            My Profile
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              navigate("settings");
            }}
          >
            My Children/Contestant details
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => setShowModal(true)}>
            Logout
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <LogoutAlertDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        logoutUser={logoutUser}
      />
    </div>
  );
};

export default Header;
