import { createSlice } from "@reduxjs/toolkit";

const guidelinePopupInitialState = {
  isShowing: false,
};

export const guidelinePopupSlice = createSlice({
  name: "guidelinePopup",
  initialState: guidelinePopupInitialState,
  reducers: {
    showGuidelinePopup: (state) => {
      state.isShowing = true;
    },
    hideGuidelinePopup: (state) => {
      state.isShowing = false;
    },
  },
});

export const selectGuidelinePopupState = (state) =>
  state.guidelinePopup.isShowing;

export const { showGuidelinePopup, hideGuidelinePopup } =
  guidelinePopupSlice.actions;

export default guidelinePopupSlice.reducer;
