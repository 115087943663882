import axios from 'axios'
import axiosConfig from './axiosConfig'

const baseURL = process.env.REACT_APP_BASE_URL

const AccountService = {
  profile: {
    editBasicDetails: ({ fullname, email }) => {
      return axios.put(
        `${baseURL}/account/profile/edit`,
        {
          fullname,
          email,
        },
        axiosConfig()
      )
    },
  },

  competition: {
    getAll: () => axios.get(`${baseURL}/contest/public/list`),
    getPublicCompetition: (userId, type) =>
      axios.get(
        `${baseURL}/contest/public/list?user_id=${userId}&type=${type}&exclude_future_contests=true`
      ),
    requestNewPageCompetition: (url, pageSize = 9) =>
      axios.get(`${url}&page_size=${pageSize}`, axiosConfig()),
    getPersonalCompetition: (type, keyword = '', pageSize = 9) =>
      axios.get(
        `${baseURL}/contest/my/list?type=${type}&search=${keyword}&page_size=${pageSize}`,
        axiosConfig()
      ),
    search: (keyword) =>
      axios.get(`${baseURL}/contest/public/list?search=${keyword}`),
    getCompetitionById: (competitionId, userId = '') =>
      axios.get(
        `${baseURL}/contest/details/${competitionId}${
          userId ? `?user_id=${userId}` : ''
        }`
      ),
    enroll: (competitionId) =>
      axios.post(
        `${baseURL}/contest/enroll`,
        {
          contest_id: competitionId,
        },
        axiosConfig()
      ),
    enrollToFreeContest: (competitionId, userId) =>
      axios.post(
        `${baseURL}/contest/enroll-free-contest`,
        {
          contest_id: competitionId,
          user_id: userId,
        },
        axiosConfig()
      ),
    getUploadedFile: (contestId, childId = '') =>
      axios.get(
        `${baseURL}/contest/${contestId}/files?child_id=${childId}`,
        axiosConfig()
      ),
    getFeedback: (contestId) =>
      axios.get(`${baseURL}/contest/${contestId}/user/feedback`, axiosConfig()),
    getCertificates: (contestId) =>
      axios.get(
        `${baseURL}/contest/${contestId}/user/certificate`,
        axiosConfig()
      ),
  },

  getReferrals: () => {
    return axios.get(`${baseURL}/referral/list`, axiosConfig())
  },

  getSubscriptionStatus: () =>
    axios.get(`${baseURL}/account/self/subscription/details`, axiosConfig()),

  getShipments: (url = `${baseURL}/account/shipments`) =>  axios.get(url, axiosConfig()),
  
  getLatestMagazine : () => axios.get(`${baseURL}/account/magazines?best_rank=true`, axiosConfig()),
  getMagazines : (url = `${baseURL}/account/magazines`) => axios.get(url, axiosConfig()),
  getAdsForPinCode: () => axios.get(`${baseURL}/account/ads_on_pincode`, axiosConfig()),
}

export default AccountService
