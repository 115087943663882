import axios from 'axios'

const baseURL = process.env.REACT_APP_BASE_URL

const AuthService = {
  loginWithEmail: (email, password) => {
    // TODO:- wait for backend api for email login.
    const promise = new Promise((resolve, reject) => {
      if (email === 'test_wizkid@email.com' && password === 'wiz123kid') {
        resolve({
          statusCode: 200,
          message: 'success',
        })
      } else {
        reject({
          statusCode: 401,
          message: 'unauthorized',
        })
      }
    })
    return promise
  },
  loginOrRegisterWithPhone: {
    OTP: {
      create: ({ country_code, mobile }) => {
        return axios.post(`${baseURL}/account/create/otp`, {
          country_code,
          mobile,
        })
      },
      verify: ({ mobile, otp }) => {
        return axios.post(`${baseURL}/account/verify/otp`, { mobile, otp })
      },
      resend: ({ country_code, mobile }) => {
        return axios.post(`${baseURL}/account/resend/otp`, {
          country_code,
          mobile,
        })
      },
    },
  },
  refreshAccessToken: (refreshToken) =>
    axios.post(`${baseURL}/auth/api/token/refresh`, {
      refresh: refreshToken,
    }),
}

export default AuthService
