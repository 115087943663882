import axios from 'axios'

import axiosConfig from './axiosConfig'

const baseURL = process.env.REACT_APP_BASE_URL

const ProfileService = {
  getProfileDetails: () => {
    return axios.get(`${baseURL}/account/self/profile/detail`, axiosConfig())
  },
  getAgents: () => {
    return axios.get(`${baseURL}/account/agent/list`, axiosConfig())
  },
  getDropdownItems: () => axios.get(`${baseURL}/basic/profile/dropdowns`),

  updateProfileDetails: (userDetails) => {
    const newDetails = { ...userDetails.parentDetails }
    newDetails.children = [...userDetails.childDetails]
    return axios.put(
      `${baseURL}/account/profile/edit`,
      newDetails,
      axiosConfig()
    )
  },
  editChildDetails: (childDetails) =>
    axios.put(`${baseURL}/account/edit/children`, childDetails, axiosConfig()),
  editProfile: (userDetails) =>
    axios.put(`${baseURL}/account/profile/edit`, userDetails, axiosConfig()),
}

export default ProfileService
