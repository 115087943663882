import { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
// import GuidelinesPopup from "../../../components/GuidelinesPopup";

import Header from "../../../components/Header";
import MobileHeader from "../../../components/MobileHeader";
import SectionLoading from "../../../components/SectionLoading";
import SideNavigation from "../../../components/SideNavigation";
import AccountService from "../../../services/AccountService";
// import {
//   hideGuidelinePopup,
//   selectGuidelinePopupState,
// } from "../../../utils/store/slice/guidelinePopup";
import {
  selectSidebarState,
  toggleSidebarState,
} from "../../../utils/store/slice/sidebarState";
import { setSubscriptionState } from "../../../utils/store/slice/subscriptionStatus";
import AuthGuard from "./AuthGuard";
import NotFoundScreen from "../../../components/NotFoundScreen";

const Dashboard = lazy(() => import("../../../pages/account/dashboard"));
const MyCompetitions = lazy(() =>
  import("../../../pages/account/myCompetitions")
);
const Shipments = lazy(() => import("../../../pages/account/shipments"));
const Magazines = lazy(() => import("../../../pages/account/magazines"));
const MyReferrals = lazy(() => import("../../../pages/account/myReferrals"));
const Settings = lazy(() => import("../../../pages/account/settings"));
const UpgradePlan = lazy(() => import("../../../pages/account/upgradePlan"));
const UploadFile = lazy(() =>
  import("../../../pages/account/myCompetitions/pages/UploadFile")
);
const Certificates = lazy(() =>
  import("../../../pages/account/myCompetitions/pages/certificates")
);
const Feedbacks = lazy(() =>
  import("../../../pages/account/myCompetitions/pages/feedbacks")
);

const KPLContests = lazy(() => import("../../../pages/account/KPLContests"));

const AccountRouter = () => {
  const sidebarStateDispatch = useDispatch();
  const sidebarState = useSelector(selectSidebarState);
  const setSubscriptionStateDispatch = useDispatch();
  // const guidelinePopupState = useSelector(selectGuidelinePopupState);
  // const guidelinePopupDispatch = useDispatch();
  // const hideGuidelineModal = () => {
  //   guidelinePopupDispatch(hideGuidelinePopup());
  // };

  // TODO: If required add this guideline popup back.

  useEffect(() => {
    const w = window.innerWidth;
    if (w <= 600) {
      sidebarStateDispatch(toggleSidebarState(false));
    } else {
      sidebarStateDispatch(toggleSidebarState(true));
    }
  }, [sidebarStateDispatch]);

  useEffect(() => {
    AccountService.getSubscriptionStatus().then((res) => {
      if (res.data.response_code === 80) {
        setSubscriptionStateDispatch(setSubscriptionState(res.data.data));
      }
    });
  }, [setSubscriptionStateDispatch]);

  return (
    <div className="d-flex account-layout">
      <div className="side-nav-wrapper">
        <SideNavigation />
      </div>
      <div
        className={`d-flex flex-column flex-grow-1 ${
          sidebarState ? "open-width" : "close-width"
        }`}
      >
        <Header />
        <MobileHeader />
        {/* <GuidelinesPopup
          showModal={guidelinePopupState}
          hideModal={hideGuidelineModal}
        /> */}
        <div className="h-100 flex-grow-1 px-1 px-md-3 position-relative">
          <Suspense fallback={<SectionLoading />}>
            <Routes>
              <Route
                path="dashboard"
                element={<AuthGuard component={<Dashboard />} />}
              />
              <Route
                path="kids-premier-league"
                element={<AuthGuard component={<KPLContests />} />}
              />
              <Route
                path="my-competitions"
                element={<AuthGuard component={<MyCompetitions />} />}
              />
              <Route
                path="my-competitions/certificates/:contestId"
                element={<AuthGuard component={<Certificates />} />}
              />
              <Route
                path="my-competitions/feedbacks/:contestId"
                element={<AuthGuard component={<Feedbacks />} />}
              />
              <Route
                path="my-referrals"
                element={<AuthGuard component={<MyReferrals />} />}
              />
              <Route
                path="my-shipments"
                element={<AuthGuard component={<Shipments />} />}
              />
              <Route
                path="my-magazines"
                element={<AuthGuard component={<Magazines />} />}
              />
              <Route
                path="upgrade-plan"
                element={<AuthGuard component={<UpgradePlan />} />}
              />
              <Route
                path="settings"
                element={<AuthGuard component={<Settings />} />}
              />
              <Route
                path="/my-competitions/upload-files/:contestId"
                element={<AuthGuard component={<UploadFile />} />}
              />
              <Route path="*" element={<NotFoundScreen top="40%" />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default AccountRouter;
