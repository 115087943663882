import { createSlice } from "@reduxjs/toolkit";

const selectedChildInitialData = {
  childData: {},
};

export const selectedChildSlice = createSlice({
  name: "selectedChild",
  initialState: selectedChildInitialData,
  reducers: {
    setSelectedChildDataToStore: (state, action) => {
      state.childData = action.payload;
    },
    resetSelectedChildData: (state) => {
      state.childData = {};
    },
  },
});

export const selectSelectedChildData = (state) => state.selectedChild.childData;

export const { setSelectedChildDataToStore, resetSelectedChildData } =
  selectedChildSlice.actions;

export default selectedChildSlice.reducer;
