const Slide = ({ slideName, activeIndex, handleSelect }) => {
  const handleSlideButtonClick = (e) => {
    let label = e.target.ariaLabel.toString();
    handleSelect(parseInt(label[label.length - 1]) - 1);
  };

  const getSlideDescription = () => {
    switch (slideName) {
      case "slide-1":
        return "Discover Your Child's Talents";
      case "slide-2":
        return "Showcase it to the world";
      case "slide-3":
        return "Imagine | Create | Win";
      default:
        return "";
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="w-100 h-100">
        <img
          className="kid-1"
          src={`/assets/onboarding-res/${slideName}/kid-1.png`}
          alt="kid-1"
        />
        <img
          className="kid-2"
          src={`/assets/onboarding-res/${slideName}/kid-2.png`}
          alt="kid-2"
        />
        <img
          className="kid-3"
          src={`/assets/onboarding-res/${slideName}/kid-3.png`}
          alt="kid-3"
        />
        <img
          className="kid-4"
          src={`/assets/onboarding-res/${slideName}/kid-4.png`}
          alt="kid-4"
        />
        <img
          className="small-img tune"
          src="/assets/onboarding-res/tune.png"
          alt="tune"
        />
        <img
          className="small-img pen"
          src="/assets/onboarding-res/pen.png"
          alt="pen"
        />
        <img
          className="small-img book"
          src="/assets/onboarding-res/book.png"
          alt="book"
        />
        <img
          className="small-img thumbs-up"
          src="/assets/onboarding-res/thumbs-up.png"
          alt="thumbs-up"
        />
        <img
          className="blob Vector_1"
          src="/assets/onboarding-res/Vector_1.png"
          alt="Vector_1"
        />
        <img
          className="blob Vector_2"
          src="/assets/onboarding-res/Vector_2.png"
          alt="Vector_2"
        />
        <img
          className="blob Vector_3"
          src="/assets/onboarding-res/Vector_3.png"
          alt="Vector_3"
        />
        <img
          className="blob Vector_4"
          src="/assets/onboarding-res/Vector_4.png"
          alt="Vector_4"
        />
        <div className="main-message">
          <div className="title">
            {slideName === "slide-2"
              ? "Global Platform for Kids to showcase their talent"
              : "Welcome to Wizkids Carnival"}
          </div>
          <div className="subtitle text-secondary fs-5">
            {getSlideDescription()}
          </div>
          <div className="carousel-indicators">
            <button
              type="button"
              onClick={handleSlideButtonClick}
              data-bs-target=""
              aria-label="Slide 1"
              aria-current={activeIndex === 0 ? "true" : "false"}
              className={activeIndex === 0 ? "active" : ""}
            ></button>
            <button
              type="button"
              onClick={handleSlideButtonClick}
              data-bs-target=""
              aria-label="Slide 2"
              aria-current={activeIndex === 1 ? "true" : "false"}
              className={activeIndex === 1 ? "active" : ""}
            ></button>
            <button
              type="button"
              onClick={handleSlideButtonClick}
              data-bs-target=""
              aria-label="Slide 3"
              aria-current={activeIndex === 2 ? "true" : "false"}
              className={activeIndex === 2 ? "active" : ""}
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide;
