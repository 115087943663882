import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectChildrenData } from "../../utils/store/slice/userData";
import { Link, useLocation } from "react-router-dom";

import "./style.scss";
import Select from "react-select";
import ChildrenProfilesDropDown from "./components/ChildrenProfilesDropDown";
import {
  setSelectedChildDataToStore,
  selectSelectedChildData,
} from "../../utils/store/slice/selectedChild";
import { Search } from "react-feather";

const CustomDropDownLabel = ({ childData }) => {
  return (
    <div className="d-flex align-items-center justify-content-start gap-3">
      <div className="dropdown-option-img-container">
        <img
          src={childData?.profileImage || "/assets/user-solid.png"}
          alt={childData?.fullName || "Profile image"}
          className="child-image"
        />
      </div>
      <div>{childData?.fullName}</div>
    </div>
  );
};

const ChildSelect = ({ maxChildrenToShowInCard }) => {
  const selectedChildData = useSelector(selectSelectedChildData);
  const childrenData = useSelector(selectChildrenData);
  const [selectedChild, setSelectedChild] = useState({});
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [childrenQueue, setChildrenQueue] = useState([]);

  const selectedChildDataDispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    if (selectedChildData && Object.keys(selectedChildData).length > 0) {
      setSelectedChild(selectedChildData);
    } else if (childrenData) {
      setSelectedChild(childrenData[0]);
      setChildrenQueue(
        [...childrenData].filter((val, i) => i < maxChildrenToShowInCard),
      );
      selectedChildDataDispatch(setSelectedChildDataToStore(childrenData[0]));
    }
  }, [
    childrenData,
    maxChildrenToShowInCard,
    selectedChildData,
    selectedChildDataDispatch,
  ]);

  const updateQueue = (selectedChild) => {
    const arr = [...childrenQueue];
    const foundAtIndex = arr.findIndex((item) => item.id === selectedChild.id);
    if (foundAtIndex >= 0 && foundAtIndex < arr.length) {
      arr.unshift(arr[foundAtIndex]);
      arr.splice(foundAtIndex + 1, 1);
    } else {
      arr.shift();
      arr.unshift(selectedChild);
    }
    setChildrenQueue(arr);
  };

  const customFilter = (option, searchText) =>
    option.data.fullName
      .toLocaleLowerCase()
      .includes(searchText.toLocaleLowerCase())
      ? true
      : false;

  const handleChildSelect = (selectedOption) => {
    setSelectedChild(selectedOption);
    updateQueue(selectedOption);
    setShowSearchInput(false);
    selectedChildDataDispatch(setSelectedChildDataToStore(selectedOption));
  };

  useEffect(() => {
    setShowSearchInput(false);
  }, [location]);

  return childrenData.length > 0 ? (
    <div
      className={`child-dropdown min-w-100 ${
        showSearchInput
          ? "select-rounded-upper-corner"
          : "select-rounded-corner"
      }`}
    >
      <ChildrenProfilesDropDown
        onClick={() => {
          setShowSearchInput(!showSearchInput);
        }}
        childrenDetails={childrenQueue}
        setSelectedChild={handleChildSelect}
        isSearchBoxShowing={showSearchInput}
      />
      {showSearchInput && (
        <Select
          classNamePrefix="react-select"
          className="custom-multi-select filter-input"
          isSearchable={true}
          autoFocus={true}
          closeMenuOnSelect={true}
          menuIsOpen={showSearchInput}
          options={childrenData}
          controlShouldRenderValue={false}
          filterOption={customFilter}
          getOptionLabel={(option) => (
            <CustomDropDownLabel childData={option} />
          )}
          getOptionValue={(option) => option}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#b34bdf",
              background: "#fbf6fd",
            },
          })}
          value={selectedChild}
          onChange={handleChildSelect}
          placeholder={
            <div className="d-flex align-items-center gap-1">
              <Search size={18} />
              <div style={{ paddingTop: "2px", fontSize: "16px" }}>Search</div>
            </div>
          }
        />
      )}
    </div>
  ) : (
    <div className="text-md-end dark-blue-text fs-5 w-100">
      No child linked to this account,{" "}
      <Link
        className="fs-5 text-decoration-none d-inline"
        to="/account/settings"
      >
        add child
      </Link>
    </div>
  );
};

ChildSelect.defaultProps = {
  maxChildrenToShowInCard: 2,
};

export default React.memo(ChildSelect);
