import { createSlice } from "@reduxjs/toolkit";
import { capitalizeEachWordInString } from "../../commonFunctions";

const userInitialData = {
  userId: "",
  name: "",
  phoneNumber: "",
  email: "",
  profilePictureUrl: "",
  children: [],
  subscription: {
    name: "",
    detailedBenefits: "",
    perks: [],
    subscriptionExpiryDate: "",
  },
};

const formatContestQualificationLevel = (data) => ({
  id: data?.id,
  levelName: data?.level_name,
  levelNumber: data?.level_number,
  minimumQualificationLevel: data?.minimum_qualification_level,
});

export const userDataSlice = createSlice({
  name: "userData",
  initialState: userInitialData,
  reducers: {
    setUserData: (state, action) => {
      state.userId = action.payload.user_id;
      state.name = capitalizeEachWordInString(action.payload.fullname);
      state.email = action.payload.email;
      state.phoneNumber = action.payload.mobile;
      state.profilePictureUrl = action.payload.profile_image;
      state.children =
        action.payload?.children.length > 0
          ? action.payload.children.map((data) => {
              const childObj = {
                id: data?.id,
                childId: data?.child_id,
                fullName: data?.fullname,
                dateOfBirth: data?.date_of_birth,
                profileImage: data?.profile_image,
                age: data?.age,
                grade: data?.grade,
                schoolName: data?.school_name,
                fieldOfInterest: data?.field_of_interest,
                teacherMobileNumber: data?.teacher_mobile_number,
                contestQualificationLevel: formatContestQualificationLevel(
                  data?.contest_qualification_level
                ),
              };
              return childObj;
            })
          : [];
      state.subscription = {
        name: action.payload?.subscription?.name,
        detailedBenefits: action.payload?.subscription?.detailed_benefits,
        perks: action.payload?.subscription?.perks?.split("\r\n"),
        subscriptionExpiryDate: action.payload?.subscription_expiry_date,
      };
    },
    resetUserData: (state) => {
      Object.assign(state, {
        userId: "",
        name: "",
        phoneNumber: "",
        email: "",
        profilePictureUrl: "",
        children: [],
        subscription: {
          name: "",
          detailedBenefits: "",
          perks: [],
          subscriptionExpiryDate: "",
        },
      });
    },
  },
});

export const selectUserData = (state) => state.userData;
export const selectChildrenData = (state) => state.userData.children;
export const selectSubscriptionData = (state) => state.userData.subscription;

export const { setUserData, resetUserData } = userDataSlice.actions;

export default userDataSlice.reducer;
