import { useEffect } from "react";
import Loading from "../../components/Loading";

const Home = () => {
  useEffect(() => {
    window.location.href = "https://wizkidscarnival.com";
  }, []);

  return (
    <div className="d-flex flex-column " style={{ minHeight: "100vh" }}>
      <Loading />
    </div>
  );
};

export default Home;
