const SupportLinks = () => {
  return (
    <>
      <a
        className="support-link m-0 p-1 ms-auto"
        href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_SUPPORT_NUMBER}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src="/assets/whatsapp-support-button.svg"
          alt="whatsapp-support-button"
        />
      </a>
      <a
        className="support-link m-0 p-1 "
        href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src="/assets/email-support-button.svg"
          alt="email-support-button"
        />
      </a>
    </>
  );
};

export default SupportLinks;
