import { Link } from "react-router-dom";

const NotFoundScreen = ({ top }) => {
  return (
    <div
      style={{
        position: "absolute",
        top,
        left: " 50%",
        transform: "translate(-50%, -50%)",
      }}
      className="w-100"
    >
      <div className="display-5 dark-blue-text font-satoshi-medium mb-4">
        <span className="font-satoshi-medium text-danger display-3">404!</span>{" "}
        Page not found.
      </div>
      <Link to="/account/dashboard" className="primary-button link-button">
        Go To Dashboard
      </Link>
    </div>
  );
};

NotFoundScreen.defaultProps = {
  top: "50%",
};

export default NotFoundScreen;
