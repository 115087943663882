import { Modal } from "react-bootstrap";
import "./style.scss";

const LogoutAlertDialog = ({ show, onHide, logoutUser }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="alert-box"
    >
      <Modal.Body className="py-5 px-4 d-flex flex-column justify-content-center align-items-center">
        <div>
          <img src="/assets/logout-popup.svg" alt="" className="me-3" />
        </div>
        <div className="text-center">
          <h2 className="dark-blue-text font-satoshi-medium mt-4">
            Do you want to logout?
          </h2>
          <div
            onClick={onHide}
            className=" d-flex gap-4 w-100 justify-content-center mt-5"
          >
            <button className="primary-transparent-button text-decoration-underline">
              No, I don’t
            </button>
            <button onClick={logoutUser} className="primary-button">
              Yes, I do
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LogoutAlertDialog;
