import LocalStorage from "./LocalStorage";

const axiosConfig = () => {
  const accessToken = LocalStorage.get.accessToken();
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export default axiosConfig;
