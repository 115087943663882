import React from "react";

const LogoutLogo = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.93359 5.03991C6.14026 2.63991 7.37359 1.65991 10.0736 1.65991H10.1603C13.1403 1.65991 14.3336 2.85325 14.3336 5.83325V10.1799C14.3336 13.1599 13.1403 14.3532 10.1603 14.3532H10.0736C7.39359 14.3532 6.16026 13.3866 5.94026 11.0266'
        stroke='#292D32'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.99975 8H2.41309'
        stroke='#292D32'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.90033 5.7666L1.66699 7.99994L3.90033 10.2333'
        stroke='#292D32'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default LogoutLogo;
