import { Spinner } from "react-bootstrap";

const SectionLoading = () => {
  return (
    <div className='d-flex align-items-center justify-content-center w-100 h-100'>
      <Spinner animation='border' />
    </div>
  );
};

export default SectionLoading;
