import React from "react";

const PlansLogo = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.24707 5.91992H11.7471'
        stroke='#0A174C'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.25293 5.91992L4.75293 6.41992L6.25293 4.91992'
        stroke='#0A174C'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.24707 10.5867H11.7471'
        stroke='#0A174C'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.25293 10.5867L4.75293 11.0867L6.25293 9.58667'
        stroke='#0A174C'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.99967 14.6666H9.99967C13.333 14.6666 14.6663 13.3333 14.6663 9.99992V5.99992C14.6663 2.66659 13.333 1.33325 9.99967 1.33325H5.99967C2.66634 1.33325 1.33301 2.66659 1.33301 5.99992V9.99992C1.33301 13.3333 2.66634 14.6666 5.99967 14.6666Z'
        stroke='#0A174C'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PlansLogo;
