import { useState, useEffect } from "react";
import { Menu, ChevronDown } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../../utils/store/slice/userData";
import { Dropdown, DropdownButton } from "react-bootstrap";
import LogoutAlertDialog from "../LogoutAlertDialog";
import useAuth from "../../utils/customHooks/useAuth";
import {
  selectSidebarState,
  toggleSidebarState,
} from "../../utils/store/slice/sidebarState";
import "./style.scss";

const MobileHeader = () => {
  // const [notificationCount, setNotificationCount] = useState(0);
  const [profilePicURL, setProfilePicURL] = useState("");
  const [showModal, setShowModal] = useState(false);
  const userData = useSelector(selectUserData);
  const isSidebarOpen = useSelector(selectSidebarState);
  const sidebarStateDispatch = useDispatch();
  const navigate = useNavigate();
  const { logoutUser } = useAuth();
  // const getNotificationCount = () => {
  //   // TODO:- get notification count from backend
  //   setNotificationCount(1);
  // };

  useEffect(() => {
    if (userData.profilePictureUrl) {
      setProfilePicURL(userData.profilePictureUrl);
    } else {
      setProfilePicURL("/assets/user-solid.png");
    }
  }, [userData]);

  const openSidebar = () => {
    isSidebarOpen
      ? sidebarStateDispatch(toggleSidebarState(false))
      : sidebarStateDispatch(toggleSidebarState(true));
  };

  return (
    <div className="w-100 d-flex d-md-none px-2 py-3 mobile-header">
      <button onClick={openSidebar} className="menu-button notification-button">
        <Menu />
      </button>
      {/* <button className='ms-auto notification-button border-0 me-2'>
        <Search /> |THIS_IS_A_COMMENT- import search from react-feather|
      </button>
      <button className='me-2 primary-transparent-button notification-button'>
        <img
          src='/assets/notification.svg'
          alt='notifications'
          width='32px'
          height='32px'
        />
        {notificationCount && (
          <span className='notification-count'>
            {notificationCount > 10 ? "9+" : notificationCount}
          </span>
        )}
      </button> */}
      {/* Remove ms-auto form below line while uncommenting the above code  */}
      <div className="profile-dropdown m-0 ms-auto">
        <DropdownButton
          className="p-0"
          variant=""
          title={
            <span className="d-flex align-items-center">
              <div
                className="profile-pic"
                style={{ backgroundImage: `url(${profilePicURL})` }}
              ></div>

              <ChevronDown />
            </span>
          }
        >
          <Dropdown.Item
            onClick={() => {
              navigate("settings");
            }}
          >
            Edit Profile
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              navigate("settings");
            }}
          >
            Edit Child details
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => setShowModal(true)}>
            Logout
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <LogoutAlertDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        logoutUser={logoutUser}
      />
    </div>
  );
};

export default MobileHeader;
