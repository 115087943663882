import Cookies from "js-cookie";

const CookiesService = {
  set: {
    geoLocation: (obj) => {
      Cookies.set("geoTargetData", JSON.stringify(obj));
    },
  },
  get: {
    geoLocation: () => {
      try {
        const geoTargetData = Cookies.get("geoTargetData");
        if (geoTargetData) {
          return JSON.parse(geoTargetData);
        } else {
          return geoTargetData;
        }
      } catch (error) {
        console.log("Error in parsing geo location cookies data.");
        return undefined;
      }
    },
  },
  clear: {
    geoLocation: () => {
      Cookies.remove("geoTargetData");
    },
  },
};

export default CookiesService;
