import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useMatch, useNavigate } from "react-router-dom";
import { setUserData } from "../../../utils/store/slice/userData";
import AuthService from "../../../services/AuthService";
import ProfileService from "../../../services/profileServices";
import SectionLoading from "../../../components/SectionLoading";
import LocalStorage from "../../../services/LocalStorage";

import "./style.scss";

const AuthGuard = ({ component }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState("");
  const setUserDataDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const competitionDetailsPageMatch = useMatch(
    "/competition/details/:contestId"
  );

  useEffect(() => {
    if (location.pathname) {
      setRedirectTo(location.pathname);
    } else {
      setRedirectTo("/account/dashboard");
    }
  }, [location.pathname]);

  const goToAuthentication = () => {
    LocalStorage.clear();
    navigate("/authentication", {
      replace: true,
      state: {
        redirectTo,
      },
    });
  };

  const refreshAccessToken = () => {
    const refreshToken = LocalStorage.get.refreshToken();
    AuthService.refreshAccessToken(refreshToken)
      .then((res) => {
        LocalStorage.set.accessToken(res.data.access);
      })
      .catch(() => {
        goToAuthentication();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getUserData = async () => {
    ProfileService.getProfileDetails()
      .then((res) => {
        setUserDataDispatch(setUserData(res.data.data));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            refreshAccessToken();
          } else {
            goToAuthentication();
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isUserLoggedIn = () => {
    let accessToken = LocalStorage.get.accessToken();
    if (accessToken) {
      getUserData();
      return true;
    } else {
      return false;
    }
  };

  const getCallbackPath = useCallback(
    () => location.state?.callbackPath,
    [location]
  );

  const getRedirectPath = useCallback(
    () => location.pathname,
    [location.pathname]
  );

  return isUserLoggedIn() ? (
    !isLoading ? (
      component
    ) : (
      <SectionLoading />
    )
  ) : competitionDetailsPageMatch ? (
    component
  ) : (
    <Navigate
      to="/authentication"
      replace
      state={{
        redirectTo: getRedirectPath(),
        callbackPath: getCallbackPath(),
      }}
    />
  );
};

export default AuthGuard;
