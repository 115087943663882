import { useState } from "react";
import "./style.scss";
import { ChevronDown } from "react-feather";
import { useEffect } from "react";

const ChildrenProfilesDropDown = ({
  childrenDetails,
  onClick,
  isSearchBoxShowing,
  setSelectedChild,
}) => {
  const [selectedChildIndex, setSelectedChildIndex] = useState(0);

  const selectChild = ({ childIndex }) => {
    setSelectedChildIndex(childIndex);
    setSelectedChild(childrenDetails[childIndex]);
  };

  useEffect(() => {
    setSelectedChildIndex(0);
  }, [childrenDetails]);

  return (
    childrenDetails?.length > 0 && (
      <div
        className="dropdown-child-profile-wrapper d-flex"
        role="button"
        onClick={onClick}
      >
        <div className="selected-child-name">
          {childrenDetails[selectedChildIndex]?.fullName}
        </div>
        <div className="children-image-wrapper">
          {childrenDetails.map((data, i) => (
            <div
              key={i}
              className={`child-image-container ms-auto ${
                selectedChildIndex !== i ? "not-selected-child" : ""
              }`}
              role="button"
              onClick={(e) => {
                e.stopPropagation();
                selectChild({ childIndex: i });
              }}
            >
              <img
                className="child-image"
                src={data?.profileImage || "/assets/user-solid.png"}
                alt={data?.fullName}
              />
              <span className="selected-tick">
                <img src="/assets/selected-check.svg" alt="selected" />
              </span>
            </div>
          ))}
          <div
            className={`transition ${
              isSearchBoxShowing ? "rotate-180" : "rotate-0"
            }`}
          >
            <ChevronDown />
          </div>
        </div>
      </div>
    )
  );
};

export default ChildrenProfilesDropDown;
