import { useState } from "react";
import { Carousel } from "react-bootstrap";

import Slide from "./Slide";

const OnboardingSlides = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="slides d-none d-md-block">
      <Carousel activeIndex={index} onSelect={handleSelect} className="h-100">
        <Carousel.Item className="h-100">
          <Slide
            slideName={"slide-1"}
            activeIndex={index}
            handleSelect={handleSelect}
          />
        </Carousel.Item>
        <Carousel.Item className="h-100">
          <Slide
            slideName={"slide-2"}
            activeIndex={index}
            handleSelect={handleSelect}
          />
        </Carousel.Item>
        <Carousel.Item className="h-100">
          <Slide
            slideName={"slide-3"}
            activeIndex={index}
            handleSelect={handleSelect}
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default OnboardingSlides;
