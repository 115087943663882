import React from "react";

const SettingsLogo = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z'
        stroke='#0A174C'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.33301 8.58679V7.41345C1.33301 6.72012 1.89967 6.14679 2.59967 6.14679C3.80634 6.14679 4.29967 5.29345 3.69301 4.24679C3.34634 3.64679 3.55301 2.86679 4.15967 2.52012L5.31301 1.86012C5.83967 1.54679 6.51967 1.73345 6.83301 2.26012L6.90634 2.38679C7.50634 3.43345 8.49301 3.43345 9.09967 2.38679L9.17301 2.26012C9.48634 1.73345 10.1663 1.54679 10.693 1.86012L11.8463 2.52012C12.453 2.86679 12.6597 3.64679 12.313 4.24679C11.7063 5.29345 12.1997 6.14679 13.4063 6.14679C14.0997 6.14679 14.673 6.71345 14.673 7.41345V8.58679C14.673 9.28012 14.1063 9.85345 13.4063 9.85345C12.1997 9.85345 11.7063 10.7068 12.313 11.7535C12.6597 12.3601 12.453 13.1335 11.8463 13.4801L10.693 14.1401C10.1663 14.4535 9.48634 14.2668 9.17301 13.7401L9.09967 13.6135C8.49967 12.5668 7.51301 12.5668 6.90634 13.6135L6.83301 13.7401C6.51967 14.2668 5.83967 14.4535 5.31301 14.1401L4.15967 13.4801C3.55301 13.1335 3.34634 12.3535 3.69301 11.7535C4.29967 10.7068 3.80634 9.85345 2.59967 9.85345C1.89967 9.85345 1.33301 9.28012 1.33301 8.58679Z'
        stroke='#0A174C'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SettingsLogo;
