import { Link } from "react-router-dom";

import OnboardingSlides from "../../../components/OnboardingSlides";

const AuthLayout = (component) => () =>
  (
    <div className="d-flex flex-row auth-layout">
      <OnboardingSlides />
      <div className="auth-form text-center text-md-start">
        <header>
          <Link to="/">
            <img src="/assets/app-logo.svg" alt="wiz-kid-carnival" />
          </Link>
        </header>
        {component}
      </div>
    </div>
  );

export default AuthLayout;
