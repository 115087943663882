const CupLogo = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.09998 11V12.4"
        stroke="#0A174C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.76666 14.6667H11.4333V14C11.4333 13.2667 10.8333 12.6667 10.1 12.6667H6.1C5.36666 12.6667 4.76666 13.2667 4.76666 14V14.6667V14.6667Z"
        stroke="#0A174C"
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M4.09998 14.6667H12.1"
        stroke="#0A174C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99998 10.6666C5.41998 10.6666 3.33331 8.57998 3.33331 5.99998V3.99998C3.33331 2.52665 4.52665 1.33331 5.99998 1.33331H9.99998C11.4733 1.33331 12.6666 2.52665 12.6666 3.99998V5.99998C12.6666 8.57998 10.58 10.6666 7.99998 10.6666Z"
        stroke="#0A174C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.64665 7.76669C3.14665 7.60669 2.70665 7.31336 2.35999 6.96669C1.75999 6.30002 1.35999 5.50002 1.35999 4.56669C1.35999 3.63336 2.09332 2.90002 3.02665 2.90002H3.45999C3.32665 3.20669 3.25999 3.54669 3.25999 3.90002V5.90002C3.25999 6.56669 3.39999 7.19336 3.64665 7.76669Z"
        stroke="#0A174C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3533 7.76669C12.8533 7.60669 13.2933 7.31336 13.64 6.96669C14.24 6.30002 14.64 5.50002 14.64 4.56669C14.64 3.63336 13.9067 2.90002 12.9733 2.90002H12.54C12.6733 3.20669 12.74 3.54669 12.74 3.90002V5.90002C12.74 6.56669 12.6 7.19336 12.3533 7.76669Z"
        stroke="#0A174C"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CupLogo;
