import { configureStore } from "@reduxjs/toolkit";

import userDataReducer from "./slice/userData";
import sidebarStateReducer from "./slice/sidebarState";
import subscriptionStatusReducer from "./slice/subscriptionStatus";
import toastDataReducer from "./slice/toastData";
import guidelinePopupReducer from "./slice/guidelinePopup";
import selectedChildReducer from "./slice/selectedChild";

export default configureStore({
  reducer: {
    userData: userDataReducer,
    sidebarState: sidebarStateReducer,
    subscriptionStatus: subscriptionStatusReducer,
    toastData: toastDataReducer,
    guidelinePopup: guidelinePopupReducer,
    selectedChild: selectedChildReducer,
  },
});
