import Router from "./routing/Router";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import "./App.scss";
import store from "./utils/store";

function App() {
  return (
    <Provider store={store}>
      <div className='App'>
        <Router />
      </div>
    </Provider>
  );
}

export default App;
