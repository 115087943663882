const { createSlice } = require("@reduxjs/toolkit");

const initialSubscriptionStatus = {
  isComboAvailable: false,
  isPremiumUser: false,
  enrollmentLeft: 0,
};

const subscriptionStatusSlice = createSlice({
  name: "subscriptionStatus",
  initialState: initialSubscriptionStatus,
  reducers: {
    setSubscriptionState: (state, action) => {
      state.isComboAvailable = action.payload.is_subscribed;
      state.isPremiumUser = action.payload.is_premium_user;
      state.enrollmentLeft = action.payload.enrollment_left;
    },
  },
});

export const selectSubscriptionStatus = (state) => state.subscriptionStatus;

export const selectIsComboAvailable = (state) =>
  state.subscriptionStatus.isComboAvailable;

export const selectIsPremiumUser = (state) =>
  state.subscriptionStatus.isPremiumUser;

export const selectEnrollmentLeft = (state) =>
  state.subscriptionStatus.enrollmentLeft;

export const { setSubscriptionState } = subscriptionStatusSlice.actions;

export default subscriptionStatusSlice.reducer;
