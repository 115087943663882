import aes from 'crypto-js/aes'
import encUtf8 from 'crypto-js/enc-utf8'

const secretKey = process.env.REACT_APP_API_TOKEN_SECRET_KEY

const encryptToken = (token) => {
  return aes.encrypt(token, secretKey).toString()
}

const decryptToken = (ciphertext) => {
  const bytes = aes.decrypt(ciphertext, secretKey)
  const originalText = bytes.toString(encUtf8)
  return originalText
}

const LocalStorage = {
  set: {
    accessToken: (accessToken) => {
      window.localStorage.setItem('accessToken', encryptToken(accessToken))
    },
    refreshToken: (refreshToken) => {
      window.localStorage.setItem('refreshToken', encryptToken(refreshToken))
    },
    basicUserData: (basicData) => {
      window.localStorage.setItem('basicUserData', JSON.stringify(basicData))
    },
  },
  get: {
    accessToken: () => {
      try {
        const accessToken = decryptToken(
          window.localStorage.getItem('accessToken')
        )
        return accessToken
      } catch (err) {
        return null
      }
    },
    refreshToken: () => {
      try {
        const refreshToken = decryptToken(
          decryptToken(window.localStorage.getItem('refreshToken'))
        )
        return refreshToken
      } catch (err) {
        return null
      }
    },
    basicUserData: () =>
      JSON.parse(window.localStorage.getItem('basicUserData')),
  },
  clear: () => {
    window.localStorage.removeItem('accessToken')
    window.localStorage.removeItem('refreshToken')
    window.localStorage.removeItem('phoneNumber')
  },
  clearBasicUserData: () => {
    window.localStorage.removeItem('basicUserData')
  },
}

export default LocalStorage
