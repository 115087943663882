import React from "react";

const CompetitionLogo = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.77967 9.33325H2.66634C1.93301 9.33325 1.33301 9.93325 1.33301 10.6666V14.6666H5.77967V9.33325Z'
        stroke='#0A174C'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.88677 6.66675H7.10677C6.37344 6.66675 5.77344 7.26675 5.77344 8.00008V14.6667H10.2201V8.00008C10.2201 7.26675 9.62677 6.66675 8.88677 6.66675Z'
        stroke='#0A174C'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3331 11.3333H10.2197V14.6666H14.6664V12.6666C14.6664 11.9333 14.0664 11.3333 13.3331 11.3333Z'
        stroke='#0A174C'
        strokeWidth='0.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.34682 1.38013L8.70015 2.08679C8.74682 2.18679 8.87349 2.28012 8.98015 2.29346L9.62015 2.40013C10.0268 2.46679 10.1268 2.76678 9.83349 3.05345L9.33349 3.55345C9.24682 3.64011 9.20015 3.80012 9.22682 3.91345L9.36682 4.52679C9.48015 5.01346 9.22015 5.20013 8.79348 4.9468L8.19349 4.59346C8.08682 4.5268 7.90682 4.5268 7.80015 4.59346L7.20015 4.9468C6.77349 5.20013 6.51349 5.01346 6.62682 4.52679L6.76682 3.91345C6.79349 3.80012 6.74682 3.63345 6.66015 3.55345L6.16682 3.06012C5.87349 2.76679 5.96682 2.47344 6.38015 2.40678L7.02015 2.30013C7.12682 2.28013 7.25349 2.1868 7.30015 2.09346L7.65349 1.38678C7.84682 1.00011 8.15349 1.00013 8.34682 1.38013Z'
        stroke='#0A174C'
        strokeWidth='0.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CompetitionLogo;
